var { Elm } = require("../elm/App.elm");

function getIntVal(attr) {
  var x = localStorage.getItem(attr);
  if (!x) {
    return null;
  }
  var parsed = parseInt(x, 10);
  if (isNaN(parsed)) {
    return null;
  }
  return parsed;
}

function getCookie(name) {
  var re = new RegExp(name + "=([^;]+)");
  var value = re.exec(document.cookie);
  return value != null ? unescape(value[1]) : null;
}

let jwt = getCookie("jwt");

if (jwt) {
  var app = Elm.App.init({
    flags: {
      weatherFileID: getIntVal("weatherFileID"),
      runPeriodID: getIntVal("runPeriodID"),
      fragmentCount: getIntVal("fragmentCount") || 1,
      convergenceDays: getIntVal("convergenceDays") || 1,
      energyPlusVersion: localStorage.getItem("energyPlusVersion"),
      startDate: localStorage.getItem("startDate"),
      endDate: localStorage.getItem("endDate"),
      daysPerFragment: getIntVal("daysPerFragment") || 1,
      jwt: jwt
    }
  });

  app.ports.setAttribute.subscribe(function(data) {
    console.log("setting", data);
    localStorage.setItem(data.attribute, data.value);
  });

  app.ports.setStringAttribute.subscribe(function(data) {
    console.log("setting", data);
    localStorage.setItem(data.attribute, data.value);
  });

  app.ports.bugsnag.subscribe(({ name, errors }) => {
    window.bugsnagClient.notify(new Error(name), {
      metaData: {
        errors
      }
    });
  });
} else {
  let { location } = window;
  let { href } = location;

  if (
    href.startsWith("http://localhost") ||
    href.startsWith("http://0.0.0.0")
  ) {
    window.location = "http://localhost:3010";
  } else {
    window.location = "https://auth.bractlet.com/";
  }
}
